@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'inter-ui';
/*! Template Name: Dashbyte - Premium Admin & Dashboard Template Author: Themepixels Version: 1.0.2 Website: http://themepixels.me/demo/dashbyte Contact: themepixels@gmail.com */
/* @import"https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=block";
@import"https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=block";
@import"https://fonts.googleapis.com/css2?family=Archivo:wght@300;400;500;600;700&display=block";
@import"https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600&display=block"; */

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family:
		'Inter UI',
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Cantarell',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif;
}

:focus-visible {
	outline: none;
}

img,
video {
	max-width: 100%;
	height: fit-content;
}

/* colors  */
.bg-primary100 {
	background-color: #eae0fb !important;
}

.bg-primary200 {
	background-color: #845ae0 !important;
}

.bg-primary300 {
	background-color: #7252d3 !important;
}

.bg-primary400 {
	background-color: #4e37b6 !important;
}

.bg-primary500 {
	background-color: #2d218f !important;
}

.primary100 {
	color: #eae0fb !important;
}

.primary200 {
	color: #845ae0 !important;
}

.primary300 {
	color: #7252d3 !important;
}

.primary400 {
	color: #4e37b6 !important;
}

.primary500 {
	color: #2d218f !important;
}

.bg-complete100 {
	background-color: #d3eeff !important;
}

.bg-complete200 {
	background-color: #0f8ff9 !important;
}

.bg-complete300 {
	background-color: #0072ec !important;
}

.bg-complete400 {
	background-color: #004fbf !important;
}

.bg-complete500 {
	background-color: #00318e !important;
}

.complete100 {
	color: #d3eeff !important;
}

.complete200 {
	color: #0f8ff9 !important;
}

.complete300 {
	color: #0072ec !important;
}

.complete400 {
	color: #004fbf !important;
}

.complete500 {
	color: #00318e !important;
}

.bg-success100 {
	background-color: #d6f7f0 !important;
}

.bg-success200 {
	background-color: #26bf93 !important;
}

.bg-success300 {
	background-color: #19ad79 !important;
}

.bg-success400 {
	background-color: #0d935b !important;
}

.bg-success500 {
	background-color: #04733e !important;
}

.success100 {
	color: #d6f7f0 !important;
}

.success200 {
	color: #26bf93 !important;
}

.success300 {
	color: #19ad79 !important;
}

.success400 {
	color: #0d935b !important;
}

.success500 {
	color: #04733e !important;
}

.bg-warning100 {
	background-color: #fffde1 !important;
}

.bg-warning200 {
	background-color: #ffe858 !important;
}

.bg-warning300 {
	background-color: #ffd945 !important;
}

.bg-warning400 {
	background-color: #daab2d !important;
}

.bg-warning500 {
	background-color: #aa7918 !important;
}

.warning100 {
	color: #fffde1 !important;
}

.warning200 {
	color: #ffe858 !important;
}

.warning300 {
	color: #ffd945 !important;
}

.warning400 {
	color: #daab2d !important;
}

.warning500 {
	color: #aa7918 !important;
}

.bg-danger100 {
	background-color: #fde2da !important;
}

.bg-danger200 {
	background-color: #e6533c !important;
}

.bg-danger300 {
	background-color: #d83c31 !important;
}

.bg-danger400 {
	background-color: #b91e1e !important;
}

.bg-danger500 {
	background-color: #900f17 !important;
}

.danger100 {
	color: #fde2da !important;
}

.danger200 {
	color: #e6533c !important;
}

.danger300 {
	color: #d83c31 !important;
}

.danger400 {
	color: #b91e1e !important;
}

.danger500 {
	color: #900f17 !important;
}

.bg-grey100 {
	background-color: #dbe6e8 !important;
}

.bg-grey200 {
	background-color: #475b6b !important;
}

.bg-grey300 {
	background-color: #3b4752 !important;
}

.bg-grey400 {
	background-color: #2b3947 !important;
}

.bg-grey500 {
	background-color: #1b2839 !important;
}

.grey100 {
	color: #dbe6e8 !important;
}

.grey200 {
	color: #475b6b !important;
}

.grey300 {
	color: #3b4752 !important ;
}

.grey400 {
	color: #2b3947 !important;
}

.grey500 {
	color: #1b2839 !important;
}

.bg-menu100 {
	background-color: #929aac !important;
}

.bg-menu300 {
	background-color: #2b303b !important;
}

.bg-menu500 {
	background-color: #21252d !important;
}

.menu100 {
	color: #929aac !important;
}

.menu300 {
	color: #2b303b !important;
}

.menu500 {
	color: #21252d !important;
}

.bg-contrast-lowest {
	background-color: #fff !important;
}

.bg-contrast-lower {
	background-color: #f4f4f4 !important;
}

.bg-contrast-low {
	background-color: #e0e0e0 !important;
}

.bg-contrast-medium {
	background-color: #757575 !important;
}

.bg-contrast-high {
	background-color: #4b4b4b !important;
}

.bg-contrast-higher {
	background-color: #212121 !important;
}

.contrast-lowest {
	color: #fff !important;
}

.contrast-lower {
	color: #f4f4f4 !important;
}

.contrast-low {
	color: #e0e0e0 !important;
}

.contrast-medium {
	color: #757575 !important;
}

.contrast-high {
	color: #4b4b4b !important;
}

.contrast-higher {
	color: #212121 !important;
}

/* SVG Icon Fill Color */
.icon-fill-white {
	fill: #ffffff !important;
}
.icon-fill-primary100 {
	fill: #eae0fb !important;
}
.icon-fill-primary200 {
	fill: #845ae0 !important;
}
.icon-fill-primary300 {
	fill: #7252d3 !important;
}
.icon-fill-primary400 {
	fill: #4e37b6 !important;
}
.icon-fill-primary500 {
	fill: #2d218f !important;
}
.icon-fill-complete100 {
	fill: #d3eeff !important;
}
.icon-fill-complete200 {
	fill: #0f8ff9 !important;
}
.icon-fill-complete300 {
	fill: #0072ec !important;
}
.icon-fill-complete400 {
	fill: #004fbf !important;
}
.icon-fill-complete500 {
	fill: #00318e !important;
}
.icon-fill-success100 {
	fill: #d6f7f0 !important;
}
.icon-fill-success200 {
	fill: #26bf93 !important;
}
.icon-fill-success300 {
	fill: #19ad79 !important;
}
.icon-fill-success400 {
	fill: #0d935b !important;
}
.icon-fill-success500 {
	fill: #04733e !important;
}
.icon-fill-warning100 {
	fill: #fffde1 !important;
}
.icon-fill-warning200 {
	fill: #ffe858 !important;
}
.icon-fill-warning300 {
	fill: #ffd945 !important;
}
.icon-fill-warning400 {
	fill: #daab2d !important;
}
.icon-fill-warning500 {
	fill: #aa7918 !important;
}
.icon-fill-danger100 {
	fill: #fde2da !important;
}
.icon-fill-danger200 {
	fill: #e6533c !important;
}
.icon-fill-danger300 {
	fill: #d83c31 !important;
}
.icon-fill-danger400 {
	fill: #b91e1e !important;
}
.icon-fill-danger500 {
	fill: #900f17 !important;
}
.icon-fill-grey100 {
	fill: #dbe6e8 !important;
}
.icon-fill-grey200 {
	fill: #475b6b !important;
}
.icon-fill-grey300 {
	fill: #3b4752 !important;
}
.icon-fill-grey400 {
	fill: #2b3947 !important;
}
.icon-fill-grey500 {
	fill: #1b2839 !important;
}

/* Badge Color */
.badge-primary100 {
	background: #eae0fb;
}
.badge-primary200 {
	background: #845ae0;
	color: #fff;
}
.badge-primary300 {
	background: #7252d3;
	color: #fff;
}
.badge-primary400 {
	background: #4e37b6;
	color: #fff;
}
.badge-primary500 {
	background: #2d218f;
	color: #fff;
}
.badge-complete100 {
	background: #d3eeff;
}
.badge-complete200 {
	background: #0f8ff9;
	color: #fff;
}
.badge-complete300 {
	background: #0072ec;
	color: #fff;
}
.badge-complete400 {
	background: #004fbf;
	color: #fff;
}
.badge-complete500 {
	background: #00318e;
	color: #fff;
}
.badge-success100 {
	background: #d6f7f0;
}
.badge-success200 {
	background: #26bf93;
	color: #fff;
}
.badge-success300 {
	background: #19ad79;
	color: #fff;
}
.badge-success400 {
	background: #0d935b;
	color: #fff;
}
.badge-success500 {
	background: #04733e;
	color: #fff;
}
.badge-warning100 {
	background: #fffde1;
	color: #000;
}
.badge-warning200 {
	background: #ffe858;
	color: #000;
}
.badge-warning300 {
	background: #ffd945;
	color: #000;
}
.badge-warning400 {
	background: #daab2d;
	color: #000;
}
.badge-warning500 {
	background: #aa7918;
	color: #000;
}
.badge-danger100 {
	background: #fde2da;
}
.badge-danger200 {
	background: #e6533c;
	color: #fff;
}
.badge-danger300 {
	background: #d83c31;
	color: #fff;
}
.badge-danger400 {
	background: #b91e1e;
	color: #fff;
}
.badge-danger500 {
	background: #900f17;
	color: #fff;
}
.badge-grey100 {
	background: #dbe6e8;
}
.badge-grey200 {
	background: #475b6b;
	color: #fff;
}
.badge-grey300 {
	background: #3b4752;
	color: #fff;
}
.badge-grey400 {
	background: #2b3947;
	color: #fff;
}
.badge-grey500 {
	background: #1b2839;
	color: #fff;
}
input::placeholder {
	font-family:
		'Inter UI',
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Cantarell',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif;
}

input.placeholdercolor::placeholder {
	color: white;
	font-family:
		'Inter UI',
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Cantarell',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5,
p,
a {
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

@media (max-width: 1400px) {
	body,
	p {
		font-size: 13px;
		line-height: 20px;
	}

	h1,
	.h1 {
		font-size: 33px;
		line-height: 44px;
		letter-spacing: -0.08px;
	}

	h2,
	.h2 {
		font-size: 28px;
		line-height: 40px;
	}

	h3,
	.h3 {
		font-size: 24px;
		line-height: 35.88px;
	}

	h4,
	.h4 {
		font-size: 18px;
		line-height: 33.88px;
	}

	h5,
	.h5 {
		font-size: 16px;
		line-height: 25.88px;
	}
}

@layer components {
	h1,
	.h1,
	h2,
	.h2,
	h3,
	.h3,
	h4,
	.h4,
	h5,
	.h5 {
		@apply my-2.5 font-medium text-[#4b4b4b];
	}

	h1,
	.h1 {
		@apply text-[44px]/[50px] tracking-[-0.0141279em];
	}

	h2,
	.h2 {
		@apply text-[30px]/[38px] tracking-[-0.021em];
	}

	h3,
	.h3 {
		@apply text-[27px]/[35px] tracking-[-0.0114923em];
	}

	h4,
	.h4 {
		@apply text-[22px]/[32px] tracking-[-0.00865734em];
	}

	h5,
	.h5 {
		@apply text-[18px]/[26px] tracking-[-0.00630069em];
	}

	p {
		@apply mb-2.5 block whitespace-normal !text-[14px]/[21px] font-normal not-italic tracking-[0.00177646em] text-[#4b4b4b] text-inherit;
	}

	.small-text {
		@apply !text-[12px]/[18px] tracking-[0.00849077em];
	}

	.smaller-text {
		@apply !text-[10px]/[16px] tracking-[0.00849077em];
	}

	.highlighted-text {
		@apply bg-[#000] p-[.1875em] text-[#fff];
	}

	.btn,
	.btn-small,
	.btn-xlarge,
	.btn-default,
	.btn-large,
	.btn-primary,
	.btn-complete,
	.btn-warning,
	.btn-success,
	.btn-edit,
	.btn-danger,
	.btn-tiny {
		@apply relative mb-0 inline-flex cursor-pointer items-center justify-center whitespace-nowrap rounded-[2px] border border-solid bg-white px-[9px] text-center text-[14px] font-medium leading-4  tracking-[-0.006em] text-[#4b4b4b] shadow-none hover:border-[#f4f4f4] hover:bg-[#f4f4f4] hover:text-[#212121]  active:border-[#c7c6c6] active:bg-[#e0e0e0] active:text-[#454444];
	}
	.btn-tiny {
		@apply min-h-[20px] min-w-[20px] px-[4px] text-[10px] tracking-normal;
	}
	.btn-small {
		@apply min-h-[22px] min-w-[22px] px-[5px] text-[12px] tracking-normal;
	}
	.btn-default {
		@apply min-h-[24px] min-w-[24px] rounded-[2px] px-[8px] text-[13px]/[13px] tracking-normal;
	}
	.btn-xlarge {
		@apply min-h-[35px] min-w-[35px] rounded-[2px] px-3 text-[14px] tracking-[-0.011em];
	}
	.btn-large {
		@apply min-h-[28px] min-w-[28px] rounded-[2px] px-[10px] text-[14px] tracking-[-0.008em];
	}

	.badge-default {
		@apply inline-block items-center !rounded-[10px] !px-[6px] !py-0.5 !text-[11px] font-semibold;
	}
	.badge-tiny {
		@apply inline-block !max-h-4 items-center !rounded-[10px] !px-[6px] !py-0.5 !text-[8px] font-semibold;
	}
	.badge-large {
		@apply inline-block items-center !rounded-[14px] !px-[8px] !py-1 !text-[14px] font-semibold;
	}

	.btn-toggle {
		@apply mb-0 inline-flex cursor-pointer items-center justify-center whitespace-nowrap border border-solid bg-white px-[9px] text-center text-[14px] font-medium leading-4 tracking-[-0.006em] 
    text-[#4b4b4b] shadow-none hover:!border-solid hover:bg-[#f4f4f4] hover:text-[#212121] active:bg-[#e0e0e0] active:text-[#454444];
	}
	.btn-primary,
	.btn-complete,
	.btn-warning,
	.btn-success,
	.btn-edit,
	.btn-danger {
		@apply border-[1px] border-solid text-[#fff];
	}

	.btn-primary {
		@apply border-[#4E37B6] bg-[#845ae0] hover:border-[#7654D6] hover:bg-[#7654D6] hover:text-[#fff] active:border-[#8259DF] active:bg-[#8259DF];
	}

	.btn-complete {
		@apply border-[#004FBF] bg-[#0072EC] hover:border-solid hover:border-[#0072EC] hover:bg-[#0f8ff9] hover:text-[#fff] active:bg-[#0072EC];
	}

	.btn-warning {
		@apply border-[#ffe858] bg-[#ffe858] text-[#000] hover:border-solid hover:border-[#ffe858] hover:bg-[#ffe858] active:border-solid active:bg-[#FFd945] active:text-[#000];
	}

	.btn-success {
		@apply border-[#0D935B] bg-[#19AD79] hover:border-[#19AD79] hover:bg-[#26bf93] hover:text-[#fff] active:bg-[#19AD79] active:text-[#fff];
	}

	.btn-edit {
		@apply border-solid border-[#2B3947] bg-[#3B4752] text-[#fff] hover:border-[#475b6b] hover:bg-[#475b6b] hover:text-[#fff] active:bg-[#2f3e50] active:text-[#fff];
	}

	.btn-danger {
		@apply border-solid border-[#B91E1E] bg-[#D83C31] hover:border-[#e6533c] hover:bg-[#e6533c] hover:text-[#fff] active:bg-[#D83C31] active:text-[#fff];
	}

	.btn-large.border-r-0.rounded-none {
		border-right: 0;
		border-radius: 0;
	}

	.btn-social {
		@apply relative inline-flex w-full items-center justify-center px-[12px];
	}

	.social-icons {
		@apply absolute left-[12px] inline-flex items-center justify-center text-[14px];
	}

	.btn-icon {
		@apply ml-[-6px] mr-[2px] mt-[2px] inline-flex items-center justify-center px-[4px] text-[12px] font-normal leading-3 tracking-normal;
	}

	input,
	select,
	optgroup,
	textarea {
		@apply m-0 leading-none text-inherit;
	}

	.form-control-1 {
		@apply h-8 border-[1px] border-solid border-[#A3A2A0] px-[10px] text-[14px] text-[#444444];
	}

	.form-group {
		@apply mb-[10px];
	}

	.form-control {
		@apply block w-full rounded-[0.25rem] rounded-sm border-[1px] border-solid border-[#ccd2da] bg-[#fff] px-[0.75rem] py-[0.406rem] text-[0.875rem]/[1.5] font-normal text-[#41505f];
		@apply block h-[32px] w-full rounded-[2px] border-[1px] border-solid border-[#A3A2A0] bg-[#fff] px-2.5 py-0 text-[0.875rem]/[1.5] text-[14px] font-normal text-[#444444] shadow-none placeholder:text-[#888888] focus:border-[#2D3047];
	}

	.form-control {
		transition:
			border-color 0.15s ease-in-out,
			box-shadow 0.15s ease-in-out;
	}

	input,
	button,
	select,
	textarea {
		font-family: inherit;
	}

	.form-group:last-child {
		@apply !mt-0;
	}

	.form-group .label {
		@apply relative !mb-0 inline-flex items-center py-[5px] text-[14px] font-semibold text-[#323130];
	}
	.form-group .label {
	}

	input[type='text'],
	input[type='password'],
	input[type='file'],
	input[type='email'],
	textarea {
		@apply border-[#82817F] focus:border-[1px] focus:border-solid focus:outline-none;
	}

	textarea.form-control {
		@apply h-auto min-h-[68px] !resize-none !p-[10px];
	}

	/* .quill .ql-container.ql-snow .ql-editor p{
   @apply !text-[14px]/[21px] block text-inherit whitespace-normal font-normal tracking-[0.00177646em] mb-2.5 not-italic text-[#4b4b4b]
  } */
	.form-input-group {
		@apply relative flex;
	}
	.input-group-text {
		@apply absolute bottom-0 right-0 top-0 m-auto flex w-[32px] cursor-pointer items-center justify-center rounded-none border-0 border-l-[1px] border-l-[#A3A2A0] bg-transparent p-0 text-[19px]/[10px];
	}
	.dropsearch,
	.dropsearchInput {
		@apply relative;
	}
	.listbox {
		@apply z-10 max-h-[200px] w-full overflow-hidden overflow-y-auto rounded-b-sm bg-[#fff] shadow-[#000000];
	}
	.listbox {
		box-shadow:
			rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px,
			rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px;
	}
	.listitem .list-dropdown-headings {
		@apply ml-0 flex h-8 items-center overflow-hidden text-ellipsis whitespace-nowrap px-2 text-[14px] font-semibold text-[#732774];
	}
	.listbox li {
		@apply block  cursor-pointer whitespace-pre-line px-2 py-[7.5px] text-[#323130] hover:bg-[#e1dfdd];
		font-weight: 400;
	}

	.searchInput {
		@apply relative pr-[25px];
	}

	.drop-icons {
		@apply absolute right-[6px] top-[9px] flex h-[17px] w-[16px] items-center justify-center text-[25px]/[17px] text-[#555];
	}

	.chk .checkmark {
		position: absolute;
		top: 10px;
		left: 15px;
		height: 11px;
		width: 6px;
		border: 2px solid white;
		border-top: 0;
		border-left: 0;
		transform: rotate(45deg);
	}

	.simple-checkmark {
		position: absolute;
		top: 3.3px;
		left: 7px;
		height: 11px;
		width: 6px;
		border: 2px solid white;
		border-top: 0;
		border-left: 0;
		transform: rotate(45deg);
	}
	.inverse-checkmark {
		position: absolute;
		top: 3px;
		left: 7px;
		height: 11px;
		width: 6px;
		border: 2px solid #7252d3;
		border-top: 0;
		border-left: 0;
		transform: rotate(45deg);
	}
	.check-square {
		@apply relative top-0 mb-0 h-[20px] w-5 min-w-[20px] cursor-pointer appearance-none rounded-[2px] border border-solid border-[#323130] text-[14px] outline-none checked:relative checked:border-none checked:bg-primary-300;
	}

	.simple-check-square {
		@apply relative mb-0 h-[20px] w-5 cursor-pointer appearance-none rounded-[2px] border border-solid border-[#323130] text-[14px] outline-none checked:relative checked:border-none checked:bg-primary-300;
	}

	.inverse-check-square {
		@apply relative mb-0 h-[20px] w-5 cursor-pointer appearance-none rounded-[2px] border border-solid border-[#323130] text-[14px] outline-none checked:relative checked:border-[#7252d3] checked:bg-white;
	}

	.inverse-check-square-purple {
		@apply relative mb-0 h-[20px] w-5 cursor-pointer appearance-none rounded-[2px] border border-solid border-[#7252d3] text-[14px] outline-none checked:relative checked:border-[#7252d3] checked:bg-white;
	}
	.inverse:checked::before {
		@apply absolute right-1 top-[2px] rotate-1 transform text-[14px] text-primary-300;
	}
	.check-square:checked::before {
		@apply absolute right-1 top-[2px] rotate-1 transform text-[14px] text-white;
	}

	.check-circle,
	.radio-circle {
		@apply relative top-0 mb-0  h-[1.6em] h-[20px] w-[1.6em]  w-[20px] cursor-pointer  appearance-none !rounded-full border-[1px] border-solid border-[#323130] text-[14px]  outline-none;
	}

	.circle-tick-checkmark {
		position: absolute;
		top: 50%;
		left: 50%;
		height: 11px;
		/* transform: translate(-50%,-50%); */
		width: 6px;
		border: 2px solid Green;
		border-top: 0;
		border-left: 0;
		transform: translate(-50%, -62%) rotate(45deg);

		/* transform: rotate(45deg); */
	}

	.circle-cross-checkmark {
		position: absolute;
		top: 50%;
		left: 50%;
		border: 0;
		background: transparent url('../src/assets/img/close-mark.png')
			center/cover !important;
		width: 10px;
		height: 10px;
		transform: translate(-50%, -50%);
	}

	.circle-not-checkmark {
		position: absolute;
		height: 9px;
		width: 2px;
		background-color: #999999;
		left: 9px;
		top: 4px;
		border: '2px';
	}

	.circle-not-checkmark::before {
		content: '';
		position: absolute;
		height: 2px;
		width: 2px;
		background-color: #999999;
		left: 0px;
		bottom: -3px;
	}

	.check-circle-green:checked {
		@apply border-[#359900];
	}

	.check-circle-red:checked {
		@apply border-[#f44336];
	}

	.check-circle-dark:checked {
		@apply border-[#999999];
	}

	.radio-label {
		@apply relative;
	}

	/* .radio-circle:checked+span {
    @apply w-[10px] h-[10px] bg-[#506fd9] absolute top-[5.5px] left-[5px] rounded-full
  } */
	.radio-circle:checked + span {
		position: absolute;
		height: 10px;
		width: 10px;
		border-radius: 100%;
		background-color: rgb(80 111 217);
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.alert-wrapper {
		@apply fixed left-0 right-0 top-0 z-[1000];
	}

	.alerts {
		@apply m-0 mb-[10px] flex max-h-[91px] items-center justify-between rounded-none py-[13px] pl-[15px] pr-[19px] text-[13px] shadow-none;
	}

	.alerts button {
		@apply cursor-pointer font-bold opacity-50 hover:opacity-100;
	}

	.alert-info {
		@apply border-[#00318E] bg-[#d3eeff] text-[#00318e];
	}

	.alert-warning {
		@apply border-[#AA7918] bg-[#fffde1] text-[#aa7918];
	}

	.alert-success {
		@apply border-[#04733E] bg-[#d6f7f0] text-[#04733e];
	}

	.alert-danger {
		@apply border-[#900F17] bg-[#fde2da] text-[#900f17];
	}

	.alert-default {
		@apply border-[#e0e0e0] bg-[#fff] text-[#4b4b4b];
	}

	.notification-annimation {
		@apply relative m-[10px];
	}

	.badge {
		@apply inline-block items-center rounded-[10px] bg-[#e0e0e0] px-[6px] py-0.5 text-[11px] font-semibold text-[#4b4b4b];
	}

	.badge-success {
		@apply bg-[#19AD79] text-[#fff];
	}

	.badge-warning {
		@apply bg-[#FFd945] text-[#fff];
	}

	.badge-important {
		@apply bg-[#D83C31] text-[#fff];
	}

	.badge-info {
		@apply bg-[#3B4752] text-[#fff];
	}

	.badge-inverse {
		@apply bg-[#00318e] text-[#fff];
	}

	.dropdown-buttons {
		@apply mb-[5px] mr-[5px] inline-flex h-[23px] items-center justify-center rounded-[2px] border-[1px] border-solid border-[#506fd9] bg-[#7252d3] px-[7px] text-[11px] leading-8 text-white hover:bg-[#445eb8] hover:text-[#fff];
	}

	.multiDrop {
		@apply relative;
	}

	.dropBox {
		@apply absolute left-0 top-[100%] z-10 max-h-[200px] w-[100%] overflow-hidden overflow-y-auto rounded-l-[2px] bg-[#fff] shadow-[#000000];
	}

	.dropBox,
	.advdropBox {
		box-shadow:
			rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px,
			rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px;
	}

	.ms-Suggestions-item {
		@apply overflow-hidden overflow-ellipsis whitespace-nowrap px-[12px] py-[6.5px] text-[14px] text-[#323130] hover:bg-[#e1dfdd];
	}

	.multiDropInput {
		@apply min-h-[32px] rounded-[2px] border-[1px] border-solid border-[#A3A2A0] p-0 text-[14px] text-[#444444];
	}

	.TagItem {
		@apply relative my-[1px] box-content flex h-[26px] min-w-[100%] flex-shrink cursor-default flex-nowrap justify-between rounded-none bg-[#F4F4F4] text-[14px] font-normal leading-[26px] text-[#323130];
	}

	.TagItemRemove {
		@apply mt-[-1px] flex h-[26px] w-[26px] cursor-pointer items-center justify-center text-[18px] leading-[10px] text-[#A3A2A0] hover:bg-[#DADADA] hover:text-[#323130];
	}

	.TagItem span {
		@apply mx-2 min-w-[30px] truncate;
	}

	.TagItem:last-of-type {
		border-bottom: 1px solid rgb(163, 162, 160);
	}

	.form-control-1 {
		@apply !h-[30px] !border-0 !px-[10px] text-[14px] text-[#444444] !shadow-none;
	}

	.advDrop {
		@apply relative;
	}

	.advdropBox {
		@apply absolute left-0 top-[100%] z-10 max-h-[200px] w-[100%] overflow-hidden overflow-y-auto rounded-r-[2px] bg-[#fff] shadow-[#000000];
	}

	.advDropInput {
		@apply min-h-[32px] rounded-[2px] border-[1px] border-solid border-[#A3A2A0] p-0 text-[14px] text-[#444444];
	}

	.suggestions-item {
		@apply cursor-pointer px-[12px] py-[6.5px] text-[#323130];
	}

	.suggestions-item h6,
	.AdvancedItemInfo h6 {
		@apply m-0 text-[14px] font-semibold;
	}

	.suggestions-item p,
	.AdvancedItemInfo p {
		@apply m-0 !text-[13px];
	}

	.AdvanceTagItem {
		@apply relative box-content flex flex-shrink justify-between border-b-[1px] border-b-[#0003] text-[14px] font-normal;
	}

	.AdvancedItemInfo {
		@apply mr-[-26px] w-full px-3 py-[6.5px];
	}

	.advancedItemRemove {
		@apply mr-[3px] mt-[3px] !flex !h-[25px] !w-[25px] cursor-pointer items-center justify-center bg-[#000] !text-[18px] leading-[10px] text-[#fff];
	}

	.advDropInput.yes .AdvanceTagItem {
		@apply items-center;
	}

	.advDropInput.yes .advancedItemRemove {
		@apply mt-[1px];
	}

	.bg-red {
		@apply !bg-[#E51400] !text-[#FFFFFF];
	}

	.bg-yellow {
		@apply !bg-[#FFEB3A] !text-[#000000];
	}

	.bg-nephritis {
		@apply !bg-[#27AE60] !text-[#FFFFFF];
	}

	.bg-black {
		@apply !bg-[#000000] !text-[#FFFFFF];
	}

	.p-inputwrapper {
		@apply !w-full !border-0;
	}

	/* landing page  */
	.sidebar-body .nav-label {
		@apply relative block px-5 py-3 text-[11px] uppercase tracking-[.7px] text-[#6e7985];
	}

	.navlink {
		@apply px-4 py-2;
	}

	.side-arrow-down::after {
		content: '';
		height: 5px;
		width: 5px;
		border: 1px solid #6e7985;
		position: absolute;
		border-top: 0px;
		border-left: 0px;
		top: 16px;
		right: 15px;
		line-height: 0;
		opacity: 0.6;
		transform: rotate(45deg);
	}

	.sidebar-arrow-right::after {
		content: '';
		height: 5px;
		width: 5px;
		border: 1px solid #6e7985;
		position: absolute;
		border-top: 0px;
		border-left: 0px;
		top: 18px;
		right: 18px;
		line-height: 0;
		opacity: 0.6;
		transform: rotate(-45deg);
	}

	.header-main {
		transition: all 0.25s;
		box-shadow:
			0 1px 1px rgba(33, 40, 48, 0.01),
			0 4px 4px rgba(33, 40, 48, 0.01),
			0 16px 16px rgba(33, 40, 48, 0.01);
	}
}

/* genral css starts form here  */

.alert-annimate {
	animation: slideInDown 0.6s ease-in-out;
}
@keyframes slideInDown {
	0% {
		transform: translateY(-100%);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

/* forms  */
.pgn-bar {
	overflow: hidden;
	margin: 0;
}
.pgn {
	position: relative;
	margin: 10px;
}
.pgn-wrapper[data-position='top'] {
	top: 0;
	left: 0;
	right: 0;
}
.pgn-wrapper {
	position: fixed;
	z-index: 100;
}
.cs-security {
	height: 500px;
	width: 500px;
	background: black;
	background-image: url('./assets/svg/security.svg');
	background-position: center;
}
/* CustomPaginator.css */

/* Show 'Next' as text */
.card {
	/* margin: auto;
  max-width: 1200px;
  margin-top: 40px; */
	border-radius: 2px;
	box-shadow:
		0 1px 2px rgba(204, 210, 218, 0.07),
		0 2px 4px rgba(204, 210, 218, 0.07),
		0 4px 8px rgba(204, 210, 218, 0.07),
		0 8px 16px rgba(204, 210, 218, 0.07),
		0 16px 32px rgba(204, 210, 218, 0.07),
		0 32px 64px rgba(204, 210, 218, 0.07);
}

.card .card-body {
	padding: 15px;
	position: relative;
}

.border-1 {
	border: 1px solid #e2e5ec;
	padding: 20px;
}

.paginator .p-paginator-next-text {
	margin: 0 5px;
	color: #007bff;
	cursor: pointer;
}

.search-dropdown .p-inputwrapper {
	height: 32px;
	width: 130px;
	line-height: 10px;
	border: 1px solid rgb(163, 162, 160);
	border-radius: 0;
}

.p-dropdown-label.p-inputtext,
.p-component {
	font-size: 14px;
}

.p-dropdown.p-focus,
.p-dropdown.p-inputwrapper-focus {
	box-shadow: none;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
	background-color: transparent;
	color: rgb(75, 85, 99);
}

.p-datatable-table > :not(caption) > * {
	border-width: 1px 0;
}

thead,
tbody,
tfoot,
tr,
td,
th {
	border-style: solid;
	border-color: inherit;
}

.p-datatable-table thead tr {
	border: 0 !important;
}
/* .p-datatable-table th, */

.p-datatable-table td {
	border: 0 !important;
	border-top: 1px solid #dee2e6 !important;
	padding: 11.5px 10px;
	/* width:100%; */
	color: rgb(68, 68, 68);
}

.p-datatable-table th {
	border: 0 !important;
	border-top: 0 !important;
	border-top: 1px solid #dee2e6 !important;
	padding: 11.5px 10.5px;
	padding-left: 10px;
	/* width:100%; */
	color: rgb(68, 68, 68);
}

.p-link {
	font-size: 0.875rem;
	line-height: 21px;
	height: 35px;
}

.p-checkbox.p-component {
	height: 20px;
	width: 20px;
}
.p-checkbox-box {
	border-radius: 0;
	border: 1px solid black;
	height: 20px;
	width: 20px;
}

.p-checkbox-box.p-highlight {
	border-color: transparent;
}

.p-checkbox-box.p-focus {
	box-shadow: none;
}

.p-paginator-pages .p-link {
	font-size: 14px;
	line-height: 21px;
	height: 35px;
	width: 33px;
	/* background-color: #7252d3 !important; */
}
.p-paginator-pages.p-paginator-element.p-highlight {
	background-color: #7252d3 !important;
}
.p-paginator-prev span {
	padding: 0.375rem 0.75rem;
}

.p-paginator-bottom.p-paginator {
	display: flex;
}

.p-paginator-left-content {
	color: #000;
	font-size: 0.875rem;
	flex-grow: 1;
}

.p-datatable-header {
	margin-bottom: 20px;
	background-color: #fff;
}

.p-datatable-thead th {
	background-color: #fff;
}

.p-highlight.p-selectable-row {
	background-color: #f5f5f5;
}
.p-datatable .p-datatable-header {
	background: #fafafa;
	color: #3f3f46;
	border: 1px solid #f4f4f5;
	/* border-width: 1px 0 1px 0; */
	padding: 1.25rem 10px !important;
	font-weight: 600;
}

.p-paginator {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}
.p-paginator {
	background: #ffffff;
	color: #71717a;
	border: solid #f4f4f5;
	border-width: 0;
	padding: 1.25rem 10px;
	border-radius: 0.375rem;
}
/* notification */
.notification {
	display: flex;
	align-items: center;
	background-color: #3498db;
	color: #fff;
	padding: 15px;
	position: absolute;
	top: 2px;
	right: 2px;
	min-width: 200px;
	height: 70px;
	margin: auto;
	border-radius: 50px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	margin-bottom: 10px;
}

.icon {
	font-size: 50px;
	margin-right: 10px;
}

.content {
	display: flex;
	align-items: center;
	gap: 15px;
}

.text {
	font-size: 16px;
}

.close {
	cursor: pointer;
	font-size: 20px;
}

.timer {
	/* margin-right: 16px; */
	font-size: 13px;
}

.message h3 {
	margin: 0 0 -3px 0;
}

.spinner {
	height: 18px;
	width: 18px;
	border: 1px dashed white;
	border-radius: 50%;
	animation-name: rotation;
	animation-duration: 30s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	position: absolute;
	top: 0.2px;
	right: 8.3px;
}

@keyframes rotation {
	from {
		transform: rotate(-360deg);
	}

	to {
		transform: rotate(360deg);
	}
}

/* Date picker css */
.p-datepicker table td {
	padding: 0;
}

.ui-calendar .ui-datepicker {
	height: 200px;
}

.p-datepicker table td > span {
	width: 30px !important;
	height: 30px !important;
	border-radius: 2px;
	border: none;
	font-weight: normal;
}

.p-datepicker table td:hover {
	background-color: #eeeeee;
}

.p-datepicker table {
	font-size: 14px;
	margin: -0.143rem 0;
}

.p-datepicker .p-datepicker-header {
	padding: 0.2rem;
}

.p-datepicker.p-component.p-ripple-disabled {
	height: 255px !important;
}

.p-calendar.p-inputwrapper {
	width: 100%;
}

.calendar-container {
	width: 100% !important;
}

.p-calendar .p-inputtext.p-component {
	width: 1500px !important;
}

.p-datepicker.p-component,
.p-datepicker-group-container {
	width: 230px !important;
	min-width: 150px !important;
	max-width: 230px !important;
}

.p-datepicker.p-component.p-ripple-disabled {
	/* left: 65px !important; */
	margin-left: 4px !important;
	margin-top: 0.5px !important;
	/* margin-top: 18rem !important; */
}

.p-datepicker-calendar {
	width: 145px !important;
	min-width: 100px !important;
	max-width: 145px !important;
	margin: 0 !important;
}

[data-pc-section='tableheadercell'] {
	padding: 0 5px !important;
	width: 30px;
	height: 30px;
	border-radius: 2px;
	border: none;
	font-weight: normal;
}

.p-datepicker-header {
	padding: 0.2rem;
	margin-left: -4px;
	width: 225px !important;
	text-align: center;
	padding: 0.2rem;
	margin-left: -6px;
	font-weight: normal;
	margin-bottom: -5px;
}

.p-inputtext:enabled:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0px #6366f1;
	border-color: none;
}

.p-datepicker table td > span {
	background-color: transparent;
	border-radius: 2px !important;
}

.p-datepicker-today [data-p-highlight='true'] {
	background-color: #323130 !important;
	color: white;
}

.p-datepicker-today {
	background-color: #7252d3 !important;
	border-radius: 2px !important;
}
.p-checkbox .p-checkbox-box.p-highlight {
	border-color: #7252d3;
	background: #7252d3;
}

.p-datepicker-prev {
	font-weight: normal;
}

.p-datepicker-trigger .p-icon {
	display: block;
	vertical-align: middle;
	width: 20px;
}

.p-datepicker-trigger {
	min-width: 40px !important;
}

.calender-block {
	position: relative;
}

.p-calendar .p-inputtext.p-component {
	padding: 0px 10px !important;
	color: #444444 !important;
	height: 32px !important;
	margin-top: -1px;
	/* margin-left: -3px; */
	font-size: 14px !important;
}

.p-calendar .p-inputtext.p-component:focus {
	outline: none !important;
	border: none !important;
	border-right: none !important;
}

.p-datepicker-trigger.p-button {
	border: 0px !important;
	width: 45px !important;
	border-left: 1px solid #a3a2a0 !important;
}

.p-datepicker-trigger.p-button:enabled:focus,
.p-button:focus {
	border: 0px !important;
	border-color: 1px solid #a3a2a0 !important;
	box-shadow: 0 0 0 0 #444444 !important;
	border-left: 1px solid #a3a2a0 !important;
}

.input-container {
	color: #444444 !important;
	border: 1px solid rgb(163, 162, 160) !important;
	border-radius: 2px !important;
	height: 32px !important;
	overflow: hidden;
	font-size: 14px !important;
}

.p-datepicker-today span {
	color: #fff;
	background-color: #7252d3 !important;
	border-color: #7252d3 !important;
}

.p-datepicker-calendar .tablebodyrowprops [data-p-today='true'] {
	background-color: #004fbf !important;
}

[data-pc-section='tablebodyrowprops'] .p-highlight[data-p-highlight='true'] {
	background-color: #000 !important;
	color: #fff !important;
}

/* color picker */
.photoshop-picker {
	height: 235px !important;
	width: 246px !important;
	padding: 5px 5px;
	border-radius: 0px !important;
	position: absolute;
	margin-top: 0;
	background-color: white !important;
	box-shadow: none;
	z-index: 10;
	left: 1px;
}

.photoshop-picker .flexbox-fix {
	height: 200px !important;
	padding: 0px !important;
	gap: 5px !important;
}

.flexbox-fix > div {
	height: 200px !important;
	border: 1px !important;
	position: relative;
}

.flexbox-fix div .flexbox-fix {
	display: none !important;
}

.photoshop .flexbox-fix:nth-child(3) {
	display: none !important;
}

.photoshop-picker:first-child {
	display: none;
}

[style='background-image: linear-gradient(-180deg, rgb(240, 240, 240) 0%, rgb(212, 212, 212) 100%); border-bottom: 1px solid rgb(177, 177, 177); box-shadow: rgba(255, 255, 255, 0.2) 0px 1px 0px 0px inset, rgba(0, 0, 0, 0.02) 0px -1px 0px 0px inset; height: 23px; line-height: 24px; border-radius: 4px 4px 0px 0px; font-size: 13px; color: rgb(77, 77, 77); text-align: center;'] {
	display: none !important;
}

[style='width: 256px; height: 256px; position: relative; border-width: 2px; border-style: solid; border-color: rgb(179, 179, 179) rgb(179, 179, 179) rgb(240, 240, 240); border-image: initial; overflow: hidden;'] {
	width: 200px !important;
}

[style='position: relative; height: 256px; width: 19px; margin-left: 10px; border-width: 2px; border-style: solid; border-color: rgb(179, 179, 179) rgb(179, 179, 179) rgb(240, 240, 240); border-image: initial;'] {
	width: 40px !important;
	margin-left: 0px !important;
}

.photoshop-picker .flexbox-fix [style='width: 180px; margin-left: 10px;'] {
	display: none;
}

.custom-labels {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 2px;
	width: 250px;
	margin-top: -33px;
	z-index: 1000;
	top: 271px;
}

.color-custom-input {
	flex-grow: 2;
	padding: 0px 4px;
	font-size: 14px;
	z-index: 10;
	border: solid 1px black !important;
}

.saturation-black + div div {
	width: 8px !important;
	height: 8px !important;
	background-color: #000 !important;
	border-radius: 50% !important;
	border: solid white 1px;
	box-shadow: none !important;
	cursor: pointer;
}

.hue-vertical > div {
	height: 3.5px;
	background-color: #fff;
	width: 40px !important;
	cursor: pointer;
	border: 1px solid #000;
}

.hue-vertical {
	height: 197px;
}

/* Fonts for signature dropDown  */
@font-face {
	font-family: 'Lihataja Brush';
	src:
		url('./assets/font/Lihataja-Brush.woff2') format('woff2'),
		url('./assets/font/Lihataja-Brush.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Aerotis';
	src:
		url('./assets/font/Aerotis.woff2') format('woff2'),
		url('./assets/font/Aerotis.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Adinda Melia';
	src:
		url('./assets/font/AdindaMelia-regular.woff2') format('woff2'),
		url('./assets/font/AdindaMelia-regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Holligate Signature Demo';
	src:
		url('./assets/font/HolligateSignatureDemo.woff2') format('woff2'),
		url('./assets/font/HolligateSignatureDemo.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Bathilda';
	src:
		url('./assets/font/Bathilda.woff2') format('woff2'),
		url('./assets/font/Bathilda.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Allison Tessa';
	src:
		url('./assets/font/AllisonTessa-ExtraexpandedExtra.woff2')
			format('woff2'),
		url('./assets/font/AllisonTessa-ExtraexpandedExtra.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Honeymoon Avenue Script';
	src:
		url('./assets/font/HoneymoonAvenueScript.woff2') format('woff2'),
		url('./assets/font/HoneymoonAvenueScript.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Rhesmanisa';
	src:
		url('./assets/font/Rhesmanisa-Regular.woff2') format('woff2'),
		url('./assets/font/Rhesmanisa-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Julietta Messie';
	src:
		url('./assets/font/Julietta-Messie-Demo.woff2') format('woff2'),
		url('./assets/font/Julietta-Messie-Demo.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Broetown Signature';
	src:
		url('./assets/font/BroetownSignature.woff2') format('woff2'),
		url('./assets/font/BroetownSignature.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Bimbo';
	src:
		url('./assets/font/Bimbo-Finetip.woff2') format('woff2'),
		url('./assets/font/Bimbo-Finetip.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Prestige Signature Script  Demo';
	src:
		url('./assets/font/PrestigeSignatureScript-Demo.woff2') format('woff2'),
		url('./assets/font//PrestigeSignatureScript-Demo.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Allison Script';
	src:
		url('./assets/font/AllisonScript-Regular.woff2') format('woff2'),
		url('./assets/font/AllisonScript-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Jayadhira LILA EE 0.1';
	src:
		url('./assets/font/JayadhiraLILAEE01.woff2') format('woff2'),
		url('./assets/font/JayadhiraLILAEE01.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Beastform';
	src:
		url('./assets/font/Beastform.woff2') format('woff2'),
		url('./assets/font/Beastform.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

/* buttons */
.btn {
	font-family: inherit;
}

/* pages */

.card-sign {
	border-radius: 10px;
	box-shadow:
		0 2.8px 2.2px rgba(110, 121, 133, 0.02),
		0 6.7px 5.3px rgba(110, 121, 133, 0.028),
		0 12.5px 10px rgba(110, 121, 133, 0.035),
		0 22.3px 17.9px rgba(110, 121, 133, 0.042),
		0 41.8px 33.4px rgba(110, 121, 133, 0.05),
		0 100px 80px rgba(110, 121, 133, 0.07);
}

.header-logo,
.sidebar-logo {
	font-size: 28px;
	font-weight: 600;
	display: inline-block;
	line-height: 1;
	color: #212830;
	letter-spacing: -0.5px;
	position: relative;
}

.header-logo::after,
.sidebar-logo::after {
	content: '';
	position: absolute;
	bottom: 1.5px;
	/* right: -8px; */
	width: 6px;
	height: 6px;
	border-radius: 100%;
	background-color: #c70039;
}

.page-sign.block .col-wrapper {
	min-height: inherit;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
}

.page-sign.block .card-sign {
	border-radius: 0;
	box-shadow: none;
}

.card-sign {
	width: 100%;
	border-width: 0;
	border-radius: 0;
}

@media (min-width: 400px) {
	.card-sign {
		width: 380px;
	}
}

@media (min-width: 576px) {
	.card-sign {
		border-radius: 10px;
		box-shadow:
			0 2.8px 2.2px rgba(110, 121, 133, 0.02),
			0 6.7px 5.3px rgba(110, 121, 133, 0.028),
			0 12.5px 10px rgba(110, 121, 133, 0.035),
			0 22.3px 17.9px rgba(110, 121, 133, 0.042),
			0 41.8px 33.4px rgba(110, 121, 133, 0.05),
			0 100px 80px rgba(110, 121, 133, 0.07);
	}
}

.card-sign {
	width: 500px;
}

@media (max-width: 767px) {
	.card-sign {
		width: 100%;
	}
}

.card-sign .card-header {
	background-color: rgba(0, 0, 0, 0);
	border-bottom-width: 0;
	padding: 30px 30px 0;
}

.card-sign .card-title {
	letter-spacing: -0.5px;
	margin-bottom: 8px;
	font-weight: 400;
	font-size: 24px;
	color: #212830;
}

.card-sign .card-text {
	color: #6e7985;
	margin-bottom: 0;
}

.card-sign .card-thumb {
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;
	width: 100px;
	height: 100px;
	border-radius: 100%;
}

.card-sign .card-thumb img {
	width: 100%;
	height: 100%;
	border-radius: inherit;
	object-fit: cover;
}

.card-sign .card-body {
	padding: 6px 30px 30px;
	position: relative;
}

.card-sign .card-body small,
.card-sign .card-body .small {
	font-size: 12px !important;
	color: #6e7985 !important;
	display: block;
}

.card-sign .card-body strong {
	font-weight: 600;
	color: #212830;
}

.card-sign .divider {
	text-transform: uppercase;
	letter-spacing: 0.5px;
}

.card-sign .divider span {
	font-size: 10px;
	color: rgba(110, 121, 133, 0.75);
}

.card-sign .card-footer {
	background-color: rgba(0, 0, 0, 0);
	text-align: center;
	padding: 0 30px 30px;
	color: #6e7985;
	border-top-width: 0;
}

.validaemessage {
	margin-top: 6px;
	min-height: 31.5px;
}

.card-sign .divider {
	text-transform: uppercase;
	letter-spacing: 0.5px;
}

.divider {
	position: relative;
	display: flex;
	align-items: center;
	margin: 15px 0;
}

.divider span {
	padding: 0 10px;
	font-size: 12px;
	color: #6e7985;
}

.card-sign .divider span {
	font-size: 10px;
	color: rgba(110, 121, 133, 0.75);
}
.divider::before,
.divider::after {
	content: '';
	flex: 1;
	height: 1px;
	background-color: #e2e5ec;
}

.autho-banner-text h1 {
	font-size: 35px;
}

/* country selector  */

._1Lxpd {
	width: 100% !important;
	height: 32px !important;
	position: relative;
}

._30Ipg {
	margin: 3px 0px !important;
	width: 100% !important;
	background: none !important;
	border-radius: 5px;
	display: flex;
	height: 80%;
	width: 100px;
	position: relative;
	padding: 0 10px;
	align-items: center;
	cursor: pointer;
}

._1KMXW {
	position: absolute;
	width: 100% !important;
	margin-top: 18px;
	transition: all 0.5s;
	background: #ffffff;
	box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	z-index: 1000;
}

._1KMXW {
	width: 100% !important;
	position: absolute;
	margin-top: 0px !important;
	transition: all 0.5s;
	background: #ffffff;
	box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
	border-radius: 2px;
}

._1KMXW .svg-inline--fa {
	display: none !important;
	height: 1em;
	overflow: visible;
	vertical-align: -0.125em;
}

._3LhE5 {
	display: none !important;
}

._2-_Xn {
	padding: 10px;
	height: 200px;
	overflow-y: scroll;
}

._2vjgV {
	height: 15px;
	width: 20px;
	border-radius: 0px !important;
}

.content-left {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 30px 23px 23px;
	color: #fff;
	overflow: hidden;
	overflow-y: auto;
}

.content-box {
	height: 100vh;
	overflow: hidden;
	overflow-y: auto;
	text-align: left;
	width: 100%;
	padding: 30px;
}

.content-footer {
	margin-top: auto;
}

/* new date picker  */

.form-search {
	display: flex;
	align-items: center;
	border: 1px solid rgba(0, 0, 0, 0);
	border-radius: 2px;
	background-color: #fff;
	padding: 3px 10px;
	box-shadow:
		0 1px 2px rgba(204, 210, 218, 0.07),
		0 2px 4px rgba(204, 210, 218, 0.07),
		0 4px 8px rgba(204, 210, 218, 0.07),
		0 8px 16px rgba(204, 210, 218, 0.07),
		0 16px 32px rgba(204, 210, 218, 0.07),
		0 32px 64px rgba(204, 210, 218, 0.07);
	transition: all 0.15s;
}

.header-main .form-search .form-control,
.header-main .form-search .gridjs-input {
	padding-left: 3px;
}

/* side bar css */
.sidebar {
	/* width: 290px; */
	height: 100vh;
	position: fixed;
	top: 0;
	left: -290px;
	opacity: 0;
	visibility: hidden;
	background-color: #fff;
	z-index: 1000;
	transition: all 0.25s;
	box-sizing: border-box;
	/* transform: translateX(0px); */
}

@media (max-width: 991.98px) {
	.sidebar-show {
		overflow: hidden;
	}
	.sidebar{
		opacity: 0;
		transition: all 2ms ease-in;
	}

	.sidebar-show .sidebar {
		left: 0;
		width: 240px;
		opacity: 1;
		visibility: visible;
		transition: all 0ms ease-in-out;
		box-shadow:
			1px 0 1px rgba(33, 40, 48, 0.01),
			4px 0 4px rgba(33, 40, 48, 0.01),
			16px 0 16px rgba(33, 40, 48, 0.01);
	}
	.header-main {
		left: 240px;
		border-left: 1px solid #f8f9fc;
	}

	.sidebar-show .header-main,
	.sidebar-show .main-mobile-header,
	.sidebar-show .main {
		transform: translateX(241px);
	}
}

@media (min-width: 1400px) {
	.sidebar {
		width: 250px;
	}
}

.sidebar-offset.sidebar-show .sidebar {
	left: 0;
	box-shadow:
		1px 0 1px rgba(33, 40, 48, 0.01),
		4px 0 4px rgba(33, 40, 48, 0.01),
		16px 0 16px rgba(33, 40, 48, 0.01);
}

@keyframes slideleft {
	from {
	width: 250px;
		opacity: 0;
	}

	to {
		width: 0px;
		transform: translatex(-250px);
		opacity: 0;
	}
}
@media (min-width: 992px) {
	.sidebar {
		width: 240px;
		opacity: 1;
		visibility: visible;
		left: 0;
		box-shadow:
			1px 0 1px rgba(33, 40, 48, 0.01),
			4px 0 4px rgba(33, 40, 48, 0.01),
			16px 0 16px rgba(33, 40, 48, 0.01);
	}
	.sidebar-hide .sidebar {
		transition: all 0.45s;
		left: -250px;
		visibility: hidden;
	}

	.sidebar-hide .header-main {
		left: 0;
		border-left-width: 0;
	}

	.sidebar-hide .main {
		margin-left: 0;
	}

	.sidebar-hide .music-player {
		left: 23px;
	}
}
@media (min-width: 1400px) {
	.sidebar {
		width: 250px;
	}
}

.sidebar-body {
	height: calc(100% - 136px);
	position: relative;
}

@media (min-width: 992px) {
	.sidebar-dark {
		box-shadow: none;
	}
}
/* main page */
.main {
	min-height: calc(100vh - 7.2rem);
	margin-top: 72px;
	transition: all 0.25s;
}

@media (min-width: 992px) {
	.main {
		margin-left: 240px;
		margin-top: 0;
	}

	body:not(:has(.sidebar)) .main {
		margin-left: 0 !important;
	}
}
@media (min-width: 1400px) {
	.main {
		margin-left: 250px;
	}
	body:not(:has(.sidebar)) .main {
		margin-left: 0 !important;
	}
}
.main-outer {
	min-height: calc(100vh - 207px);
}

/* header css  */
.header-main .form-search {
	width: 180px;
	box-shadow: none;
	background-color: #f3f5f9;
}

@media (max-width: 767.98px) {
	.header-main .form-search {
		height: 38px;
		border-radius: 4px;
	}
}

@media (min-width: 576px) {
	.header-main .form-search {
		width: 270px;
	}
}

@media (min-width: 1200px) {
	.header-main .form-search {
		width: 320px;
	}
}

.header-main .form-search .form-control,
.header-main .form-search {
	padding-left: 3px;
}

/* header  profile css */

.dropdown-profile .dropdown-menu-body .fs-sm {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.dropdown-profile .nav {
	flex-direction: column;
	margin: 0 -10px;
}

.dropdown-profile .nav a {
	display: flex;
	align-items: center;
	padding: 7px 8px;
	border-radius: 4px;
	transition: all 0.25s;
}

.dropdown-profile .nav a:hover {
	background-color: #f3f5f9;
	color: #212830;
}

.dropdown-profile hr {
	opacity: 0.12;
	margin: 5px -2px;
}

.avatar {
	width: 34px;
	height: 34px;
	border-radius: 100%;
	display: block;
	position: relative;
}

.avatar img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: inherit;
	display: block;
}

.avatar.online::after,
.avatar.offline::after {
	content: '';
	position: absolute;
	bottom: 0;
	right: 5px;
	width: 6px;
	height: 6px;
	border-radius: 100%;
	box-shadow: 0 0 0 1.5px #fbfcfe;
}

.avatar.online::after {
	background-color: #0cb785;
}

.avatar.offline::after {
	background-color: #ccd2da;
}


/* .avatar-initial {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #6e7985;
	color: #fff;
	border-radius: inherit;
	font-family:
		system-ui,
		-apple-system,
		'Segoe UI',
		Roboto,
		'Helvetica Neue',
		'Noto Sans',
		'Liberation Sans',
		Arial,
		sans-serif,
		'Apple Color Emoji',
		'Segoe UI Emoji',
		'Segoe UI Symbol',
		'Noto Color Emoji';
	font-size: 16px;
	text-transform: uppercase;
} */

.avatar-xs {
	width: 20px;
	height: 20px;
}

.avatar-xs .avatar-initial {
	font-size: 9px;
}

.avatar-xs.online::after,
.avatar-xs.offline::after {
	width: 3px;
	height: 3px;
	right: 0;
}

.avatar-sm {
	width: 26px;
	height: 26px;
}

.avatar-sm .avatar-initial {
	font-size: 13px;
}

.avatar-sm.online::after,
.avatar-sm.offline::after {
	width: 5px;
	height: 5px;
	right: 3px;
}

.avatar-lg {
	width: 46px;
	height: 46px;
}

.avatar-lg .avatar-initial {
	font-size: 24px;
}

.avatar-lg.online::after,
.avatar-lg.offline::after {
	width: 7px;
	height: 7px;
}

.avatar-xl {
	width: 61px;
	height: 61px;
}

.avatar-xl .avatar-initial {
	font-size: 32px;
}

.avatar-xl.online::after,
.avatar-xl.offline::after {
	width: 8px;
	height: 8px;
	right: 7px;
	bottom: 2px;
}

.dropdown-profile .dropdown-menu {
	width: 230px;
	padding: 0;
	border-color: #e2e5ec;
	border-radius: 6px;
	border-width: 0;
	box-shadow:
		0 2px 10px rgba(110, 121, 133, 0.1),
		0 10px 20px rgba(110, 121, 133, 0.1);
}

.dropdown-profile .dropdown-menu-body {
	padding: 20px 20px 15px;
}


/* main page  */
.main-app {
	margin-top: 72px;
}

/* header Notifications css */
.dropdown-menu.dropdown-menu-end {
	position: absolute;
	inset: 0px 0px auto auto;
	margin: 0px !important;
	transform: translate(0px, 41px);
	z-index: 1000 !important;
	right: 20px;
	top: 20px;
}

.dropdown-notification .dropdown-menu {
	margin-right: 10px;
	width: 320px;
	padding: 0;
	border-color: #e2e5ec;
	border-radius: 6px;
	border-width: 0;
	box-shadow:
		0 2px 10px rgba(110, 121, 133, 0.1),
		0 10px 20px rgba(110, 121, 133, 0.1);
}


.dropdown-menu {
	border-color: rgba(65, 80, 95, 0.16);
	padding: 5px;
}

.dropdown-notification .dropdown-menu-header {
	height: 48px;
	border-bottom: 1px solid #e2e5ec;
	display: flex;
	align-items: center;
	padding: 0 15px;
}

.dropdown-notification .dropdown-menu-title {
	font-weight: 600;
	font-size: 15px;
	color: #212830;
	margin-bottom: 0;
}

.dropdown-notification .list-group {
	padding: 5px;
}

.dropdown-notification .dropdown-menu-footer {
	text-align: center;
	padding: 8px 10px;
	border-top: 1px solid #e2e5ec;
	font-size: 0.8125rem;
}
.dropdown-notification .list-group-item {
	border-width: 0;
	display: flex;
	padding: 8px 10px;
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0);
}

.dropdown-notification .list-group-item:hover {
	background-color: rgba(243, 245, 249, 0.85);
	cursor: pointer;
}

.dropdown-notification .list-group-body {
	color: #313c47;
	line-height: 1.4;
	padding-right: 10px;
}

.dropdown-notification .list-group-body p {
	margin-bottom: 2px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
	margin-top: 2px;
}

.dropdown-notification .list-group-body span {
	font-size: 11px;
}

.dropdown-notification .dropdown-menu-footer {
	text-align: center;
	padding: 8px 10px;
	border-top: 1px solid #e2e5ec;
	font-size: 0.8125rem;
}

/* mainpage footer  css*/
.main-footer {
	margin-top: 60px;
	border-top: 1px solid rgba(226, 229, 236, 0.6);
	padding-top: 20px;
	font-size: 0.8125rem;
	color: #6e7985;
}

@media (min-width: 576px) {
	.main-footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.main-footer span {
	display: block;
}

.main-footer span + span {
	margin-top: 5px;
}

@media (min-width: 576px) {
	.main-footer span + span {
		margin-top: 0;
	}
}

#sidebarMenu {
	overflow: hidden;
	overflow-y: auto;
}
#sidebarMenu:hover::-webkit-scrollbar {
	display: block;
}
#sidebarMenu::-webkit-scrollbar {
	display: none;
}
#sidebarMenu::-webkit-scrollbar {
	width: 3px;
}
#sidebarMenu::-webkit-scrollbar-track {
	background-color: rgba(58, 78, 136, 0.06);
}
#sidebarMenu::-webkit-scrollbar-thumb {
	background: #9ea7b1;
}
/* SidebarBody.css */
.tans {
	transition: 5s linear;
}
.sidebar-hide {
	transition: 3s linear ease-in-out;
}
.animate {
	transition: transform 1s;
}
.breadcrumb-item + .breadcrumb-item::before {
	float: left;
	padding-right: 0.5rem;
	color: #6e7985;
	content: '/' /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.fs-sm {
	font-size: 0.8125rem;
}

.breadcrumb-item.active {
	color: #6e7985;
}
.breadcrumb-item + .breadcrumb-item {
	padding-left: 0.5rem;
}
.header-main {
	height: 72px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 20;
	border-bottom: 1px solid #f3f5f9;
	background-color: #fff;
	display: flex;
	align-items: center;
	transition: all 0.25s;
	box-shadow:
		0 1px 1px rgba(33, 40, 48, 0.01),
		0 4px 4px rgba(33, 40, 48, 0.01),
		0 16px 16px rgba(33, 40, 48, 0.01);
}
@media (min-width: 992px) {
	.header-main {
		left: 240px;
		border-left: 1px solid #f8f9fc;
	}
}
@media (min-width: 992px) {
	.sidebar {
		width: 240px;
		opacity: 1;
		visibility: visible;
		left: 0;
		box-shadow:
			1px 0 1px rgba(33, 40, 48, 0.01),
			4px 0 4px rgba(33, 40, 48, 0.01),
			16px 0 16px rgba(33, 40, 48, 0.01);
	}
}
@media (min-width: 992px) {
	.sidebar-hide .sidebar {
		left: -250px;
		opacity: 0;
		visibility: hidden;
	}
}
@media (max-width: 991.98px) {
	.sidebar-show .sidebar {
		left: 0;
		opacity: 1;
		visibility: visible;
	}
}
.main-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 900;
	background-color: rgba(33, 40, 48, 0.6);
	opacity: 0;
	visibility: hidden;
	transition: all 0.25s;
}
@media (max-width: 991.98px) {
	.sidebar-show .main-backdrop {
		opacity: 1;
		visibility: visible;
	}
}
.transitions {
	max-height: 0;
	overflow: hidden;
	transition-property: max-height;
	transition-duration: 0.25s;
	transition-timing-function: linear;
	transition-delay: 0s;
}
.transitions.open {
	max-height: 2000px;
	transition-duration: 1s;
	transition-timing-function: ease-in;
	transition-delay: 0s;
	transform: translateY(0px);
}
.transitions.close {
	max-height: 0px;
	transition-duration: 0.25s;
	transition-timing-function: linear;
	transition-delay: 0s;
	transform: translateY(0%);
}

.divider-margin {
	margin: 23px 0;
}
.divider {
	position: relative;
	display: flex;
	align-items: center;
	margin: 15px 0;
}
.text-display {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding-bottom: 9px;
	min-height: 39px;
}
.multipleinputs > div {
	padding: 0 10px;
}
.multipleinputs.form-group:last-child {
	margin-top: 0 !important;
}

.multipleinputs .form-group {
	margin-bottom: 10px !important;
}
.multipleinputs .form-group .label {
	font-weight: 600 !important;
	font-size: 14px;
	color: rgb(50, 49, 48) !important;
	padding: 5px 0px !important;
	margin-bottom: 0px !important;
	display: inline-flex;
	align-items: center;
	position: relative;
}

.text-display .verfied {
	display: flex;
	align-items: center;
	font-weight: 600;
	color: #0cb785;
	font-size: 13px;
}
.text-display p {
	margin-bottom: 0px;
}

.text-display .verfied img {
	width: 16px;
	height: 16px;
	margin-right: 5px;
	display: block;
	margin-top: 0px;
}
.profile-header li.active:after {
	background-color: #0f2c59;
	bottom: -1px;
	content: '';
	height: 2px;
	left: 0;
	position: absolute;
	right: 0;
	border: 0;
	width: auto;
	top: auto;
	transition:
		left 0.267s cubic-bezier(0.1, 0.25, 0.75, 0.9) 0s,
		right 0.267s cubic-bezier(0.1, 0.25, 0.75, 0.9) 0s;
}

.chk-dt .p-checkbox.p-component {
	height: 20px;
	width: 20px;
}
.p-column-header-content .p-checkbox.p-component {
	height: 20px;
	width: 20px;
}
.p-column-header-content .p-checkbox.p-component .p-checkbox-box {
	height: 20px;
	width: 20px;
	border-radius: 2px !important;
}
.chk-dt .p-checkbox.p-component .p-checkbox-box {
	height: 20px;
	width: 20px;
	border-radius: 2px !important;
}

.chk-dt.chk-primary .p-checkbox .p-checkbox-box.p-highlight {
	background-color: #7252d3;
	border-color: #7252d3;
}
.chk-dt.chk-complete .p-checkbox .p-checkbox-box.p-highlight {
	background-color: #0072ec;
	border-color: #0072ec;
}
.chk-dt.chk-success .p-checkbox .p-checkbox-box.p-highlight {
	background-color: #19ad79;
	border-color: #19ad79;
}
.chk-dt.chk-warning .p-checkbox .p-checkbox-box.p-highlight {
	background-color: #ffd945;
	border-color: #ffd945;
}
.chk-dt.chk-danger .p-checkbox .p-checkbox-box.p-highlight {
	background-color: #d83c31;
	border-color: #d83c31;
}

[data-pc-section='headercheckboxicon'],
.p-checkbox.p-component .p-checkbox-box.p-highlight svg {
	margin-top: -3px;
	background-color: transparent;
	width: 6px;
	height: 11px;
	border: 2px solid white;
	border-top: 0px;
	border-left: 0px;
	transform: rotate(45deg);
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
	transition-duration: 0s;
	color: transparent;
	font-size: 0px !important;
}

/* rich text-box css  */
.ql-container.ql-snow .ql-editor {
	@apply !min-h-[100px] bg-[#fff] !p-[10px] text-[#444444];
}

.ql-container {
	box-sizing: border-box;
	font-size: 13px;
	height: 100%;
	margin: 0px;
	position: relative;
}

.ql-snow.ql-toolbar button.ql-active,
.ql-snow.ql-toolbar button:hover {
	background-color: #f3f5f9;
}

.ql-snow.ql-toolbar button {
	padding: 5px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 28px !important;
	height: 28px !important;
	color: #41505f !important;
	border-radius: 1px !important;
	margin-right: 1px;
}

.ql-snow.ql-toolbar {
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
}

.ql-snow.ql-toolbar,
.ql-snow.ql-container {
	border-color: rgb(163, 162, 160) !important;
}

.ql-snow.ql-container {
	border-bottom-right-radius: 2px;
	border-bottom-left-radius: 2px;
}

.ql-snow.ql-toolbar {
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
}
.quill .ql-container.ql-snow .ql-editor p {
	@apply mb-2.5 block whitespace-normal !text-[14px]/[21px] font-normal not-italic tracking-[0.00177646em] text-[#4b4b4b] text-inherit;
}

.scroll-bar-css::-webkit-scrollbar {
	width: auto;
}
.scroll-bar-css::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}
.scroll-bar-css::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	border: 3px solid transparent;
	border-radius: 9px;
	background-clip: content-box;
}

input[type='search']::-webkit-search-cancel-button {
	-webkit-appearance: none;
	display: inline-block;
	width: 10px;
	height: 10px;
	margin-left: 10px;
	background: linear-gradient(
			45deg,
			rgba(85, 85, 85, 0) 0%,
			rgba(85, 85, 85, 0) 43%,
			rgb(85 85 85 / var(--tw-text-opacity)) 45%,
			rgb(85 85 85 / var(--tw-text-opacity)) 55%,
			rgba(85, 85, 85, 0) 57%,
			rgba(85, 85, 85, 0) 100%
		),
		linear-gradient(
			135deg,
			transparent 0%,
			transparent 43%,
			rgb(85 85 85 / var(--tw-text-opacity)) 45%,
			rgb(85 85 85 / var(--tw-text-opacity)) 55%,
			transparent 57%,
			transparent 100%
		);
}

/*  accordion*/
/* .arrow-end{
  position:absolute;
  right:0;
  width: 0;
  height: 0;
  border-left:7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid red;
  transform: rotate(180deg);
}  */
.toggle {
	float: inline-end !important;
	display: inline-block;
	content: '';
	height: 8px;
	width: 8px;
	border-bottom: 2px solid currentColor;
	border-right: 2px solid currentColor;
	transform: rotate(45deg);
}
.peer {
	border: none !important;
	text-indent: 4px;
	border-bottom: 1px solid black !important;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
		visibility: visible;
	}
	100% {
		opacity: 0;
		visibility: hidden;
	}
}

.expand {
	animation: slideDown 0.5s ease-in-out;
}
.hide-show-element {
	transition: all 0.5s ease-in-out;
	animation: slideaway 0.3s ease-out;
	display: none;
}
.hide {
	animation: fadeOut 0.5s ease-out;
}
.toggle.active {
	transform: rotate(-135deg);
}
.main-shadow {
	box-shadow:
		0 3px 1px -2px #0003,
		0 2px 2px #00000024,
		0 1px 5px #0000001f;
}

@keyframes slideDown {
	0% {
		max-height: 0;
		opacity: 0;
	}
	100% {
		max-height: 1000px;
		opacity: 1;
	}
}

@keyframes slideaway {
	from {
		display: block;
		opacity: 0;
	}

	to {
		max-height: 0px;
		transform: translateY(-100%);
		opacity: 0;
	}
}
.no-scroll {
	position: fixed;
	overflow-y: scroll;
}

@keyframes lightSpeedOutRight {
	from {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	to {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}
}

.phone-icon {
	/* position:absolute; */
	right: 0;
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-bottom: 4px solid grey;
	transform: rotate(180deg);
	margin-top: 3px;
}
.phone-icon.rotate-icon {
	transform: rotate(-360deg);
}

/* rotating borders for notifications */
.rotating-border {
	position: relative
  }
  
  .rotating-border:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px dashed black;
	border-radius: 10px; 
	transform-origin: center; 
	animation: rotate 5s linear infinite;
  }
  
  @keyframes rotate {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }